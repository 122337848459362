<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>description</md-icon>
          </div>
          <h4 class="title">Templates</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="tableSettings.currentSort" :md-sort-order.sync="tableSettings.currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="tableSettings.pagination.perPage" name="pages">
                  <md-option
                    v-for="item in tableSettings.pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-select v-model="type" name="types">
                  <md-option :label="'experiment'" :value="'experiment'" @click.native="changeType('experiment')">Experiment</md-option>
                  <md-option :label="'section'" :value="'section'" @click.native="changeType('section')">Section</md-option>
                  <md-option :label="'screen'" :value="'screen'" @click.native="changeType('screen')">Screen</md-option>
                  <md-option :label="'element'" :value="'element'" @click.native="changeType('element')">Element</md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Search templates"
                  v-model="tableSettings.searchQuery">
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="#" :md-id="item.id" md-sort-by="id" md-numeric class="id-cell">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
              <md-table-cell md-label="Tags"><md-chips v-if="item.tags.length>0 && item.tags[0]!==''" v-model="item.tags" class="md-success static" :mdStatic="true"></md-chips></md-table-cell>
              <md-table-cell md-label="Actions" class="actions-cell">
                <md-button class="md-just-icon md-simple md-info" @click.native="$router.push({name: 'Edit Template', params: {id: item.id}})">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-simple md-danger" @click.native="showDeleteTemplateConfirm(item.id, item.name)">
                  <md-icon>delete_forever</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <pagination class="pagination-no-border pagination-success"
                        v-model="tableSettings.pagination.currentPage"
                        :per-page="tableSettings.pagination.perPage"
                        :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import {Pagination} from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'

export default {
  components: {
    Pagination
  },
  computed: {
    queriedData () {
      let result = this.tableData;
      if(this.searchedData.length > 0){
        result = this.searchedData;
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.tableSettings.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.tableSettings.pagination.perPage * (this.tableSettings.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
    }
  },
  data() {
    return {
      type: 'experiment',
      tableData: [],
      tableSettings: {
        currentSort: 'name',
        currentSortOrder: 'asc',
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [10, 25, 50],
          total: 0
        },
        searchQuery: '',
      },
      propsToSearch: ['name', 'tags'],
      searchedData: [],
      fuseSearch: null
    }
  },
  created () {
    this.loadTableSettings()
    this.loadTemplates()
  },
  methods: {
    loadTemplates () {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/templates?filters=type:' + this.type + '&excludedFields=code&paginate=true')
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response && response.response.items) {
          self.tableData = []
          response.response.items.forEach(item => {
            item.tags = item.tags.split(',')
            self.tableData.push(item)
            self.tableData = self.customSort(self.tableData)
          })
          self.fuseSearch = new Fuse(self.tableData, {keys: ['name', 'tags'], threshold: 0.3})
        } else if (xhr.status === 404) {
          self.tableData = []
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
      }
      xhr.send()
    },
    customSort (value) {
      localStorage.setItem('templatesTableSettings', JSON.stringify(this.tableSettings))
      return value.sort((a, b) => {
        const sortBy = this.tableSettings.currentSort
        if (sortBy === 'id') {
          if (this.tableSettings.currentSortOrder === 'desc') {
            return a['id'].localeCompare(b['id'], 'kn', {numeric: true})
          }
          return b['id'].localeCompare(a['id'], 'kn', {numeric: true})
        } else {
          if (this.tableSettings.currentSortOrder === 'desc') {
            return a[sortBy].localeCompare(b[sortBy])
          }
          return b[sortBy].localeCompare(a[sortBy])
        }
      })
    },
    changeType (type) {
      this.type = type
      localStorage.setItem('currentTemplatesListFilter', type)
      this.loadTemplates()
    },
    loadTableSettings () {
      localStorage.getItem('currentTemplatesListFilter')
        ? this.type = localStorage.getItem('currentTemplatesListFilter')
        : this.type = 'experiment'
      if(localStorage.getItem('templatesTableSettings')) this.tableSettings = JSON.parse(localStorage.getItem('templatesTableSettings'))
    },
    showSweetAlertError (message) {
      swal({
        title: 'An error occured!',
        text: message,
        type: 'warning',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'OK',
        cancelButtonClass: 'md-button md-success',
        buttonsStyling: false
      })
    },
    showDeleteTemplateConfirm (id, templateName) {
      swal({
        title: 'Are you sure to delete<br />' + templateName +'?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        cancelButtonText: 'No',
        confirmButtonClass: 'md-button md-success',
        cancelButtonClass: 'md-button md-danger',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          var self = this
          var xhr = new XMLHttpRequest()
          xhr.open('DELETE', this.$root.apiUrl + 'v1/templates/' + id, true)
          xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
          xhr.onload = function () {
            if (xhr.status === 401) self.$router.push({name: 'Login'})
            var response = JSON.parse(xhr.responseText)
            if (xhr.status === 201) {
              swal({
                title: 'Deleted!',
                text: templateName + ' has been deleted.',
                type: 'success',
                confirmButtonClass: 'md-button md-success',
                buttonsStyling: false
              }).then(() => {
                self.loadTemplates()
              })
            } else {
              var message
              if (response.response && response.response[0] && response.response[0].message)
                message = response.response[0].message
              else
                message = 'Unknown error'
              self.showSweetAlertError(message)
            }
          }
          xhr.send()
        }
      })
    }
  },
  watch: {
    // tableSettings: {
    //   handler(value) {
    //     localStorage.setItem('templatesTableSettings', JSON.stringify(value))
    //     let result = this.tableData
    //     result = this.customSort(result)
    //     if (value.searchQuery !== '' && this.fuseSearch !== null) {
    //       result = this.fuseSearch.search(value.searchQuery)
    //     }
    //     this.searchedData = result
    //   },
    //   deep: true
    // }
  }
}
</script>
<style lang="scss" scoped>
.text-right /deep/ .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
.md-table /deep/ .md-table-head:last-child {
  text-align: right;
}

.md-icon.active {
  color: #4caf50;
}
.md-icon.inactive {
  color: #ef5350;
}

.paginated-table table > tbody > tr > td {
  width: auto;
}
.md-table-toolbar {
  margin-bottom: 30px;
}
td .img-container {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  // -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  // box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.actions-cell .md-button {
  border: 1px #ddd solid;
  margin: 2px 0px 2px 5px;
  background-color: #fff !important;
  padding: 2px;
}
.actions-cell .md-button:hover {
  background-color: #ddd !important;
}
.id-cell {
  width: 20px !important;
  text-align: left;
}
.logo-cell {
  width: 80px !important;
}
</style>
<style lang="scss">
.md-field.md-chips.static {
  padding: 0 !important;
  position: relative;
  top: -5px;
  text-transform: uppercase;
  font-weight: 500;
}
.static .md-chip {
  margin-top: 0;
  font-size: 10px !important;
}
</style>
